<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <Column class="gallery__meta">
        <h2 class="gallery__title">{{params.title}}</h2>
        <p class="gallery__count">{{params.items && params.items.length}}
          {{ $t('service.photo') }}</p>
      </Column>
      <Slider :items="params && params.items || []"
              :height="height"
              :indentation="indentation"
              :count="count"
              component="ImageComponent"
              limiter/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gallery',
  props: {
    params: {},
  },
  computed: {
    height() {
      let height;
      if (this.$laptop) height = 450;
      if (this.$tablet) height = 180;
      if (this.$mobile) height = 180;
      return height;
    },
    count() {
      let count;
      if (this.$laptop) count = 1;
      if (this.$tablet) count = 2;
      if (this.$mobile) count = 1;
      return count;
    },
    indentation() {
      let indentation;
      if (this.$laptop) indentation = 30;
      if (this.$tablet) indentation = 30;
      if (this.$mobile) indentation = 8;
      return indentation;
    },
  },
};
</script>
